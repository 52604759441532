import React from 'react';
import PropTypes from 'prop-types';
import NewsletterListItemMobile from '../newsletterListItemMobile';

const NewsletterListMobile = ({ content }) => {
  if (content) {
    const { newsletter_items } = content;
    return (
      <div>
        {/* TODO - translation string or from Strapi */}
        <div className="mobile-newsletter-item mobile-newsletter-item--header">
          Title
        </div>
        {newsletter_items.map(item => (
          <NewsletterListItemMobile content={item} />
        ))}
      </div>
    );
  } else {
    return '';
  }
};

NewsletterListMobile.propTypes = {};

export default NewsletterListMobile;
