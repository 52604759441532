import PropTypes from 'prop-types';
import React from 'react';

const HamburgerButton = ({ burgerOpen, setBurgerOpen }) => (
  <div
    className={`burger ${burgerOpen ? 'burger--open' : ''}`}
    onClick={() => setBurgerOpen(!burgerOpen)}
  >
    <span className="burger__top"></span>
    <span className="burger__mid"></span>
    <span className="burger__btm"></span>
  </div>
);

HamburgerButton.propTypes = {
  burgerOpen: PropTypes.bool.isRequired,
  setBurgerOpen: PropTypes.func.isRequired,
};

export default HamburgerButton;
