import React from 'react';
import PropTypes from 'prop-types';

const getMapIrame = () => {
  return (
    <iframe
      src={process.env.GATSBY_GOOGLE_MAP}
      width="600"
      height="550"
      frameborder="0"
      style={{ width: '100%', height: '550', border: 0 }}
      allowfullscreen=""
      aria-hidden="false"
      tabIndex="0"
    ></iframe>
  );
};

const GoogleMap = ({ options }) => {
  if (options) {
    const { full_width } = options;
    return full_width ? (
      getMapIrame()
    ) : (
      <div className="container">
        <div className="row">
          <div className="col">{getMapIrame()}</div>
        </div>
      </div>
    );
  } else {
    return '';
  }
};

GoogleMap.propTypes = {};

export default GoogleMap;
