import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import mediaUtils from '../../utils/media-utils';

const HomepageMainFeature = ({ content }) => {
  if (content) {
    const {
      heading_quote,
      quotee,
      profile_picture,
      intro_rich_text,
      signature,
    } = content;
    return (
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-lg-10">
            <div className="row">
              <div className="col">
                <h3>{quotee}</h3>
                <h2>{heading_quote}</h2>
                <hr className="divider"></hr>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-center my-auto">
                <img
                  className="profile-picture img-fluid rounded-circle"
                  src={mediaUtils(profile_picture.url)}
                  alt=""
                />
              </div>
              <div className="col-md-5 align-self-center">
                <ReactMarkdown source={intro_rich_text} />
                <div>
                  <p>
                    <strong>{signature.name}</strong>
                    <br />
                    {signature.role}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return '';
  }
};

HomepageMainFeature.propTypes = {};

export default HomepageMainFeature;
