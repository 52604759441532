import React, { createContext, useContext, useState, useEffect } from 'react';

const NavigationContext = createContext(null);

const NavigationContextProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    width: typeof window !== `undefined` ? window.innerWidth : null,
  });
  useEffect(() => {
    if (typeof window === 'undefined') {
      console.log(
        "Exiting NavigationContextProvider.useEffect(). Server side rendering doesn't support window"
      );
      return;
    }

    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <NavigationContext.Provider value={dimensions}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
export const useWindowWidth = () => useContext(NavigationContext);
