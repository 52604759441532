import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fontAwesomeMappings from '../../utils/fontawesome-mappings';

const InfoCard = ({ data }) => {
  if (data) {
    const { type, info_heading, info_content } = data;
    return (
      <div className={`info-card info-card--${type}`}>
        <div className="info-card__icon">
          <FontAwesomeIcon icon={fontAwesomeMappings(type)} />
        </div>
        <div className="info-card__content">
          <div className="info-card__heading">
            <h3>{info_heading}</h3>
          </div>
          <div className="info-card__body">
            <ReactMarkdown source={info_content} />
          </div>
        </div>
      </div>
    );
  } else {
    return '';
  }
};

InfoCard.propTypes = {};

export default InfoCard;
