import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mediaUtils from '../../utils/media-utils';

const NewsletterListItemMobile = ({ content }) => {
  const [detailsActiveState, setDetailsActiveState] = useState('');
  const {
    newsletter_date,
    newsletter_title,
    newsletter_file,
    newsletter_tags,
  } = content;
  const { name, url, size, updatedAt } = newsletter_file;

  const toggleDetails = () => {
    setDetailsActiveState(detailsActiveState === '' ? 'active' : '');
  };

  return (
    <div className={`mobile-newsletter-item ${detailsActiveState}`}>
      <div className="mobile-newsletter-item__title" onClick={toggleDetails}>
        {newsletter_title}
        <span className="mobile-newsletter__chevron"></span>
      </div>
      <div className="mobile-newsletter-item__details">
        <div>
          <table>
            <tbody>
              <tr>
                <th scope="row">File name</th>

                <td>
                  <FontAwesomeIcon icon={['far', 'file-pdf']} />{' '}
                  {newsletter_title} {name}
                </td>
              </tr>
              <tr>
                <th scope="row">File size</th>
                <td>{size} kB</td>
              </tr>
              <tr>
                <th scope="row">Date Uploaded</th>
                <td>{moment(newsletter_date).format('LLLL')}</td>
              </tr>
              <tr>
                <th scope="row">Tags</th>
                <td className="newsletter-item__tag-cell">
                  {newsletter_tags.map(item => (
                    <span className="badge badge-light">{item.tag}</span>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="newsletter-action-cell">
          <a href={mediaUtils(url)} className="badge badge-primary">
            View
          </a>
        </div>
      </div>
    </div>
  );
};

NewsletterListItemMobile.propTypes = {};

export default NewsletterListItemMobile;
