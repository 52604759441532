import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import mediaUtils from '../../utils/media-utils';

const MediaObject = ({ content }) => {
  if (content) {
    const {
      heading,
      body_rich_text,
      media_alignment,
      image_desktop,
      image_mobile,
      date_posted,
    } = content;

    const styles = {
      backgroundImage: image_desktop
        ? `url(${mediaUtils(image_desktop.url)})`
        : '',
    };

    return (
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 media-object__image-block">
            <span style={styles}></span>
          </div>
          <div className="col-12 col-lg-6 media-object__text-block">
            <div className="media-object__text">
              {heading ? <h3>{heading}</h3> : ''}
              {date_posted ? (
                <p>
                  <span className="badge badge-primary">
                    Date posted:{' '}
                    {moment(date_posted).format('dddd Do MMMM YYYY')}
                  </span>
                </p>
              ) : (
                ''
              )}
              <ReactMarkdown source={body_rich_text} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return '';
  }
};

MediaObject.propTypes = {};

export default MediaObject;
