import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import NewsletterListDesktop from '../newsletterListDesktop';
import NewsletterListMobile from '../newsletterListMobile';
import FilterControlDropdown from '../filterControlDropdown';
import { useWindowWidth } from '../../providers/navigation-context';

/**
 * Filters newsletter items based on tag selection
 * @param {Object} content: Original NewsletterList object passed in from Strapi
 * @param {Function} newsletterListFromSearch: State for NewsletterList search
 * @param {String} searchSelection: Search selection from user input
 * @param {Function} setNewsletterList: Update state for NewsletterList object
 * @param {Function} setNewsletterListFromTags: Update state for NewsletterListFromTags object
 * @param {Array} tagSelection: Tag selection from user input
 */
function _filterNewsletterItems(
  content,
  newsletterListFromSearch,
  searchSelection,
  setNewsletterList,
  setNewsletterListFromTags,
  tagSelection
) {
  const newContent = searchSelection
    ? { ...newsletterListFromSearch }
    : { ...content };
  const tagsToFilter = tagSelection.map(selection => selection.value);
  const { newsletter_items } = newContent;
  const tagFilteredNewsletterItems = [];
  const tagsSelected = tagSelection.length;
  const searchIsEmpty = searchSelection === '';
  const tagsNotSelected = !tagSelection.length;
  const searchPopulated = searchSelection;

  for (let i = 0; i < newsletter_items.length; i += 1) {
    for (let j = 0; j < tagsToFilter.length; j += 1) {
      const { newsletter_tags } = newsletter_items[i];
      /* The some() method tests whether at least one element in the array passes
       * the test implemented by the provided function. It returns a Boolean value.
       * https://stackoverflow.com/a/38375768 */
      /* prettier-ignore */
      const tagExists = newsletter_tags.some(tags => tags.tag === tagsToFilter[j]);
      /* prettier-ignore */
      if (tagExists && !tagFilteredNewsletterItems.includes(newsletter_items[i])) {
          tagFilteredNewsletterItems.push(newsletter_items[i]);
        }
    }
  }

  if (tagsSelected && searchIsEmpty) {
    newContent.newsletter_items = tagFilteredNewsletterItems;
  } else if (tagsSelected && searchPopulated) {
    newContent.newsletter_items = tagFilteredNewsletterItems;
  } else if (tagsNotSelected && searchIsEmpty) {
    newContent.newsletter_items = content.newsletter_items;
  } else if (tagsNotSelected && searchPopulated) {
    newContent.newsletter_items = newsletterListFromSearch.newsletter_items;
  }

  setNewsletterListFromTags(newContent);
  setNewsletterList(newContent);
}

function _searchNewsletterTitles(
  content,
  newsletterListFromTags,
  searchSelection,
  setNewsletterList,
  setNewsletterListFromSearch,
  tagSelection
) {
  const newContent = tagSelection.length
    ? { ...newsletterListFromTags }
    : { ...content };
  const { newsletter_items } = newContent;
  const searchFilteredNewsletterItems = [];
  const tagsSelected = tagSelection.length;
  const searchIsEmpty = searchSelection === '';
  const tagsNotSelected = !tagSelection.length;
  const searchPopulated = searchSelection;

  for (let i = 0; i < newsletter_items.length; i += 1) {
    const { newsletter_title } = newsletter_items[i];
    if (
      newsletter_title
        .toLocaleLowerCase()
        .includes(searchSelection.toLocaleLowerCase())
    ) {
      searchFilteredNewsletterItems.push(newsletter_items[i]);
    }
  }

  if (tagsSelected && searchIsEmpty) {
    newContent.newsletter_items = newsletterListFromTags.newsletter_items;
  } else if (tagsSelected && searchPopulated) {
    newContent.newsletter_items = searchFilteredNewsletterItems;
  } else if (tagsNotSelected && searchIsEmpty) {
    newContent.newsletter_items = content.newsletter_items;
  } else if (tagsNotSelected && searchPopulated) {
    newContent.newsletter_items = searchFilteredNewsletterItems;
  }

  setNewsletterListFromSearch(newContent);
  setNewsletterList(newContent);
}

function _sortNewsletterItems(
  newsletterList,
  setNewsletterList,
  sortSelection,
  sortStrings
) {
  const newContent = { ...newsletterList };
  const { dateAscending, dateDescending, title } = sortStrings;
  let newsletter_items;
  switch (sortSelection) {
    case dateAscending:
      newContent.newsletter_items = _sortDateAscending(newsletterList);
      break;
    case dateDescending:
      newContent.newsletter_items = _sortDateDescending(newsletterList);
      break;
    case title:
      newContent.newsletter_items = _sortTitle(newsletterList);
      break;
    default:
      newContent.newsletter_items = _sortDateAscending(newsletterList);
  }
  setNewsletterList(newContent);
}

function _sortDateAscending(newsletterList) {
  const { newsletter_items } = newsletterList;
  return newsletter_items.sort(
    (a, b) => new Date(b.newsletter_date) - new Date(a.newsletter_date)
  );
}

function _sortDateDescending(newsletterList) {
  const { newsletter_items } = newsletterList;
  return newsletter_items.sort(
    (a, b) => new Date(a.newsletter_date) - new Date(b.newsletter_date)
  );
}

function _sortTitle(newsletterList) {
  const { newsletter_items } = newsletterList;
  return newsletter_items.sort((a, b) =>
    a.newsletter_title.localeCompare(b.newsletter_title)
  );
}

const NewsletterListSection = ({ content, tags }) => {
  const sortStrings = {
    dateAscending: 'date-ascending',
    dateDescending: 'date-descending',
    title: 'title',
  };
  const { dateAscending } = sortStrings;
  const { width } = useWindowWidth();
  const sortOptions = Object.values(sortStrings);

  /* TODO: Filter by year */

  const [newsletterList, setNewsletterList] = useState(content);
  const [newsletterListFromTags, setNewsletterListFromTags] = useState();
  const [newsletterListFromSearch, setNewsletterListFromSearch] = useState();
  const [tagSelection, setTagSelection] = useState([]);
  const [sortSelection, setSortSelection] = useState(dateAscending);
  const [searchSelection, setSearchSelection] = useState('');

  /** useEffect is used in place of the second paramter callback of setState
   * in a React class, to have access to state immediately after it has changed
   * and the async event has resolved (in this instance [tagSelection])
   */

  /* Tag selections */
  useEffect(() => {
    /* prettier-ignore */
    _filterNewsletterItems(
      content,
      newsletterListFromSearch,
      searchSelection,
      setNewsletterList,
      setNewsletterListFromTags,
      tagSelection
    );
  }, [tagSelection]);

  /* Sort selections */
  useEffect(() => {
    /* prettier-ignore */
    _sortNewsletterItems(
      newsletterList,
      setNewsletterList,
      sortSelection,
      sortStrings
    );
  }, [sortSelection]);

  /* Search selections */
  useEffect(() => {
    /* prettier-ignore */
    _searchNewsletterTitles(
      content,
      newsletterListFromTags,
      searchSelection,
      setNewsletterList,
      setNewsletterListFromSearch,
      tagSelection
    );
  }, [searchSelection]);

  const handleTagInputChangeEvent = event => {
    if (event.target.checked) {
      /* To add to an array using React Hooks  */
      setTagSelection([...tagSelection, event.target]);
    } else {
      /* To remove from an array using React Hooks  */
      setTagSelection(
        tagSelection.filter(item => item.value !== event.target.value)
      );
    }
  };

  const handleTextInputChangeEvent = event => {
    setSearchSelection(event.target.value);
  };

  const handleSortInputChangeEvent = event => {
    setSortSelection(event.target.value);
  };

  tags.sort((a, b) => (a.tag > b.tag ? 1 : -1));

  return (
    <div className="container">
      <div className="row">
        {/* Search options */}
        <div className="col-12 col-lg-4">
          <div className="filter-control__container">
            <div className="filter-control">
              <input
                type="text"
                placeholder="Search for a term ..."
                onChange={event => {
                  handleTextInputChangeEvent(event);
                }}
              />
              <div className="filter-control__chevron"></div>
            </div>
          </div>
        </div>
        {/* Filter options */}
        <FilterControlDropdown
          type={'checkbox-selection'}
          label="Filter by tag ..."
          criteria={tags}
          selection={tagSelection}
          eventHandler={handleTagInputChangeEvent}
        ></FilterControlDropdown>
        {/* Sort options */}
        <FilterControlDropdown
          type={'sort-selection'}
          label="Sort by category ..."
          criteria={sortOptions}
          selection={sortSelection}
          eventHandler={handleSortInputChangeEvent}
        ></FilterControlDropdown>
      </div>
      <div className="row">
        <div className="col">
          {width < 1200 ? (
            <NewsletterListMobile
              content={newsletterList}
            ></NewsletterListMobile>
          ) : (
            <NewsletterListDesktop
              content={newsletterList}
            ></NewsletterListDesktop>
          )}
        </div>
      </div>
    </div>
  );
};

NewsletterListSection.propTypes = {};

export default NewsletterListSection;
