import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import UnsplashCredit from '../unsplashCredit';

const copyrightYear = new Date().getFullYear();

/**
 * TODO: Ideally these should be maintained in the CMS
 */
const photographers = [
  {
    username: '@anniespratt',
    photographer: 'Annie Spratt',
  },
  {
    username: '@benwhitephotography',
    photographer: 'Ben White',
  },
  {
    username: '@brennan_marttinez',
    photographer: 'Brennan Martinez',
  },
  {
    username: '@jhc',
    photographer: 'James Coleman',
  },
  {
    username: '@jontyson',
    photographer: 'Jon Tyson',
  },
  {
    username: '@timgraf99',
    photographer: 'Tim Graf',
  },
];

const Footer = ({ content }) => (
  <>
    <footer className="footer footer__upper">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 footer__address">
            <ReactMarkdown source={content.footer_address} />
            <p>© {copyrightYear} Our Lady of Grace and St Teresa of Avila</p>
          </div>
          <div className="col-lg-4 footer__contact-info">
            <hr className="d-lg-none" />
            <ReactMarkdown source={content.footer_contact_info} />
            <hr className="d-lg-none" />
          </div>
          <div className="col-lg-4 footer__social">
            <ReactMarkdown source={content.footer_social} />
          </div>
        </div>
      </div>
    </footer>
    <div className="footer footer__lower">
      <div className="container">
        <div className="row">
          <div className="col">
            <p>Photography credits:</p>
            {photographers.map(details => (
              <UnsplashCredit details={details}></UnsplashCredit>
            ))}
          </div>
        </div>
      </div>
    </div>
  </>
);

Footer.propTypes = {};

export default Footer;
