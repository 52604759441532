import React from 'react';
import PropTypes from 'prop-types';
import InfoCard from '../infoCard';

const InfoCardSection = ({ content }) => {
  if (content) {
    const { Info_card } = content;
    return (
      <div className="container">
        <div className="row">
          <div className="offset-lg-1 col-lg-10">
            {Info_card.map(card => (
              <InfoCard data={card} />
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return '';
  }
};

InfoCardSection.propTypes = {};

export default InfoCardSection;
