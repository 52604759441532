import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown/with-html';
import { Link as ReactScrollLink } from 'react-scroll';
import mediaUtils from '../../utils/media-utils';

const Hero = ({ options }) => {
  if (options) {
    const { id, image, heading, rich_text_heading, stand_first, cta } = options;

    const displayHeading = () => {
      if (rich_text_heading) {
        return (
          <ReactMarkdown
            source={rich_text_heading}
            disallowedTypes={['paragraph']}
            unwrapDisallowed={true}
            escapeHtml={false}
          />
        );
      } else {
        return heading;
      }
    };

    const displayCta = () => {
      /* TODO: Technically this should become a CTA component for standalone use */
      if (cta) {
        const { link, internal_link, position, text } = cta;
        return internal_link ? (
          <ReactScrollLink
            to={link}
            smooth={true}
            className={`btn btn-primary btn--${position}`}
          >
            {text}
          </ReactScrollLink>
        ) : (
          <a href={link} className={`btn btn-primary btn--${position}`}>
            {text}
          </a>
        );
      } else {
        return '';
      }
    };

    const heroStyles = {
      backgroundImage:
        image && image.url ? `url(${mediaUtils(image.url)})` : '',
    };

    return (
      <section id={id} className="hero" style={heroStyles}>
        <div className="container">
          <div className="row">
            <div className="col-sm-8 col-lg-6">
              <h1>{displayHeading()}</h1>
              {stand_first ? <p className="stand-first">{stand_first}</p> : ''}
              {displayCta()}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return '';
  }
};

Hero.propTypes = {
  options: PropTypes.shape({
    heading: PropTypes.string,
    cta: PropTypes.shape({
      link: PropTypes.string,
      position: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
};

export default Hero;
