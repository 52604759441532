import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const RichTextSection = ({ content }) => {
  const getDirection = direction => (direction === 'Row' ? 'col' : 'col-12');
  const getAlignment = alignment => `text-${alignment.toLowerCase()}`;

  if (content) {
    const { direction, rich_text } = content;
    return (
      <div className="container">
        <div className="row">
          {rich_text.map(data => (
            <div
              className={`${getDirection(direction)} ${getAlignment(
                data.alignment
              )}`}
            >
              <ReactMarkdown source={data.content} />
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return '';
  }
};

RichTextSection.propTypes = {};

export default RichTextSection;
