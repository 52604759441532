import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Navigation from '../navigation';
import Logo from '../../images/logo.svg';

const Header = ({ siteTitle }) => (
  <header>
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
          <Navigation></Navigation>
        </div>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
