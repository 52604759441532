import PropTypes from 'prop-types';
import React from 'react';

const UnsplashCredit = ({ details }) => {
  const { username, photographer } = details;
  return (
    <a
      className="unsplash-credit"
      href={`https://unsplash.com/${username}?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge`}
      target="_blank"
      rel="noopener noreferrer"
      title={`Download free do whatever you want high-resolution photos from ${photographer}`}
    >
      <span>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <title>unsplash-logo</title>
          <path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path>
        </svg>
      </span>
      <span className="unsplash-credit__photographer">{photographer}</span>
    </a>
  );
};

UnsplashCredit.propTypes = {};

export default UnsplashCredit;
