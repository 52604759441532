import React from 'react';
import PropTypes from 'prop-types';
import HomepageMainFeature from '../homepageMainFeature';
import SimpleText from '../simpleText';
import MediaObject from '../mediaObject';
import Heading from '../heading';
import InfoCardSection from '../infoCardSection';
import RichTextSection from '../richTextSection';
import GoogleMap from '../googleMap';
import NewsletterListSection from '../newsletterListSection';

const MainContent = ({ options }) => {
  const { page, newsletterTags } = options;
  if (page) {
    return page.main.map(data => {
      let id = '';
      let component = '';
      let classList = '';
      switch (data.__typename) {
        case 'Strapi_ComponentHomepageHomepageMainFeature':
          id = data.id;
          component = <HomepageMainFeature content={data} />;
          classList = data.__typename;
          break;
        case 'Strapi_ComponentGenericSimpleText':
          id = data.id;
          component = <SimpleText options={data} />;
          classList = data.__typename;
          break;
        case 'Strapi_ComponentGenericMediaObject':
          id = data.id;
          component = <MediaObject content={data} />;
          classList = `${data.__typename} ${data.__typename}--${
            data.media_alignment
          } ${data.background_theme ? `theme--${data.background_theme}` : ''}`;
          break;
        case 'Strapi_ComponentGenericHeadingComponent':
          id = data.id;
          component = <Heading options={data} />;
          classList = `${data.__typename} ${data.__typename}--${
            data.heading_alignment
          } ${data.theme ? `theme--${data.theme}` : ''} ${
            data.padding_bottom ? 'padding--bottom' : ''
          }`;
          break;
        case 'Strapi_ComponentGenericInfoCardSection':
          id = data.id;
          component = <InfoCardSection content={data} />;
          classList = data.__typename;
          break;
        case 'Strapi_ComponentGenericGoogleMap':
          id = data.id;
          component = <GoogleMap options={data} />;
          classList = data.__typename;
          break;
        case 'Strapi_ComponentGenericRichTextSection':
          id = data.id;
          component = <RichTextSection content={data} />;
          classList = data.__typename;
          break;
        case 'Strapi_ComponentGenericNewsletterListSection':
          id = data.id;
          component = (
            <NewsletterListSection content={data} tags={newsletterTags} />
          );
          classList = data.__typename;
          break;
        default:
      }
      return (
        <section id={id} className={classList}>
          {component}
        </section>
      );
    });
  } else {
    return '';
  }
};

MainContent.propTypes = {};

export default MainContent;
