/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Header from '../header';
import Footer from '../footer';
import NavigationContextProvider from '../../providers/navigation-context';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
        }
      }
      strapi {
        footer(id: "5eec3e5cda2c3943708cd0d7") {
          footer_address
          footer_contact_info
          footer_social
        }
      }
    }
  `);

  return (
    <NavigationContextProvider>
      <Header siteTitle={data.site.siteMetadata.title} />
      {children}
      <Footer content={data.strapi.footer} />
    </NavigationContextProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
