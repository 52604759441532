import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import mediaUtils from '../../utils/media-utils';

const NewsletterListItemDesktop = ({ content }) => {
  const {
    newsletter_date,
    newsletter_title,
    newsletter_file,
    newsletter_tags,
  } = content;
  const { name, url, size, updatedAt } = newsletter_file;
  return (
    <tr className="newsletter-item">
      <td>
        <FontAwesomeIcon icon={['far', 'file-pdf']} /> {newsletter_title}
      </td>
      {/* {Time} ago --> https://stackoverflow.com/a/53017865 */}
      {/* <td>{moment(newsletter_date).fromNow()}</td> */}
      <td>{moment(newsletter_date).format('dddd, MMMM Do YYYY')}</td>
      <td className="newsletter-item__tag-cell">
        {newsletter_tags.map(item => (
          <span className="badge badge-light">{item.tag}</span>
        ))}
      </td>
      <td className="newsletter-item__action-cell">
        <a href={mediaUtils(url)} className="badge badge-primary">
          View
        </a>
      </td>
    </tr>
  );
};

NewsletterListItemDesktop.propTypes = {};

export default NewsletterListItemDesktop;
