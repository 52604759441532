import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import {
  faAddressCard,
  faEnvelopeOpenText,
  faPhoneSquareAlt,
  faFax,
  faCalendarAlt,
  faInfoCircle,
  faBookReader,
  faFilePdf,
  faCheckSquare,
  faSquare,
  faDotCircle,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  far,
  faAddressCard,
  faEnvelopeOpenText,
  faPhoneSquareAlt,
  faFax,
  faCalendarAlt,
  faInfoCircle,
  faBookReader,
  faFilePdf,
  faCheckSquare,
  faSquare,
  faDotCircle,
  faCircle
);

export default type => {
  switch (type) {
    case 'address':
      return 'address-card';
    case 'email':
      return 'envelope-open-text';
    case 'phone':
      return 'phone-square-alt';
    case 'fax':
      return 'fax';
    case 'event':
      return ['far', 'calendar-alt'];
    case 'info_generic':
      return 'book-reader';
    case 'social_facebook':
      return ['fab', 'facebook'];
    case 'social_twitter':
      return ['fab', 'twitter'];
    default:
      return '';
  }
};
