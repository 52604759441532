import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useWindowWidth } from '../../providers/navigation-context';
import HamburgerButton from '../hamburgerButton';
import NavigationMobile from '../navigationMobile';

const HOMEPAGE_ID = '5e519d4f179058821edf00a9';

const Navigation = () => {
  const [burgerOpen, setBurgerOpen] = useState(false);
  const { width } = useWindowWidth();
  const data = useStaticQuery(graphql`
    query NavQuery {
      strapi {
        pages {
          id
          show_in_navigation
          name
          title
        }
      }
    }
  `);

  const navigationLinks = data.strapi.pages.map(page => {
    if (width >= 992 && page.show_in_navigation) {
      return (
        <Link key={page.id} to={page.name}>
          {page.title}
        </Link>
      );
    }

    if (width < 992 && (page.id === HOMEPAGE_ID || page.show_in_navigation)) {
      return (
        <Link key={page.id} to={page.id === HOMEPAGE_ID ? '/' : page.name}>
          {page.title}
        </Link>
      );
    }
  });

  return (
    <nav>
      {width < 992 ? (
        <>
          <HamburgerButton
            burgerOpen={burgerOpen}
            setBurgerOpen={setBurgerOpen}
          ></HamburgerButton>
          <NavigationMobile
            navigationLinks={navigationLinks}
            burgerOpen={burgerOpen}
          ></NavigationMobile>
        </>
      ) : (
        navigationLinks
      )}
    </nav>
  );
};

export default Navigation;
