import React from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

const Heading = ({ options }) => {
  if (options) {
    const { alignment, heading, stand_first, stand_first_rich_text } = options;
    return (
      <div className="container">
        <div className="row">
          <div className="offset-lg-1 col-lg-10">
            <h1>{heading}</h1>
            {stand_first ? <p className="stand-first">{stand_first}</p> : ''}
            {stand_first_rich_text ? (
              <p className="stand-first">
                {<ReactMarkdown source={stand_first_rich_text} />}
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return '';
  }
};

Heading.propTypes = {};

export default Heading;
