import React from 'react';
import PropTypes from 'prop-types';
import NewsletterListItemDesktop from '../newsletterListItemDesktop';

const newsletterListDesktop = ({ content }) => {
  if (content) {
    const { newsletter_items } = content;
    return (
      <table className="table">
        <thead>
          <tr>
            {/* TODO - these should be strings that come from the CMS or be part of the newsletter list component */}
            <th>Title</th>
            <th>Date Uploaded</th>
            <th>Tags</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {newsletter_items.map(item => (
            <NewsletterListItemDesktop content={item} />
          ))}
        </tbody>
      </table>
    );
  } else {
    return '';
  }
};

newsletterListDesktop.propTypes = {};

export default newsletterListDesktop;
