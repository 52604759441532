import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const NavigationMobile = ({ navigationLinks, burgerOpen }) => (
  <div
    className={`nav__flyout-menu ${burgerOpen ? 'nav__flyout-menu--open' : ''}`}
  >
    {navigationLinks}
  </div>
);

NavigationMobile.propTypes = {};

export default NavigationMobile;
