import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

/* TODO: String mappings in CMS */
const SORT_STRING_MAPPINGS = {
  'date-ascending': 'Dates ascending',
  'date-descending': 'Dates descending',
  title: 'Title',
};

function _getCheckBoxSelectionMarkup(tags, tagSelection, eventHandler) {
  return (
    <ul>
      {tags.map(item => (
        <li className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value={item.tag}
            id={item.id}
            onChange={event => {
              eventHandler(event);
            }}
          />
          <label className="form-check-label" for={item.id}>
            {item.tag}
            {tagSelection.some(el => el.id === item.id) ? (
              <FontAwesomeIcon icon={['far', 'check-square']} />
            ) : (
              <FontAwesomeIcon icon={['far', 'square']} />
            )}
          </label>
        </li>
      ))}
    </ul>
  );
}

function _getSortSelectionMarkup(sortOptions, sortSelection, eventHandler) {
  return (
    <fieldset>
      {sortOptions.map(sortOption => (
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name={sortOption}
            id={sortOption}
            value={sortOption}
            checked={sortSelection === sortOption}
            onChange={event => {
              eventHandler(event);
            }}
          />
          <label className="form-check-label" for={sortOption}>
            {SORT_STRING_MAPPINGS[sortOption]}
            {sortSelection === sortOption ? (
              <FontAwesomeIcon icon={['far', 'dot-circle']} />
            ) : (
              <FontAwesomeIcon icon={['far', 'circle']} />
            )}
          </label>
        </div>
      ))}
    </fieldset>
  );
}

function _getSelectionMarkup(type, criteria, selection, eventHandler) {
  switch (type) {
    case 'checkbox-selection':
      return _getCheckBoxSelectionMarkup(criteria, selection, eventHandler);
    case 'sort-selection':
      return _getSortSelectionMarkup(criteria, selection, eventHandler);
  }
}

function getLabel(label, selection, type) {
  if (type === 'checkbox-selection') {
    return selection.length
      ? label.replace('...', `(${selection.length})`)
      : label;
  }
  return label;
}

const FilterControlDropdown = ({
  type,
  label,
  criteria,
  selection,
  eventHandler,
}) => {
  const [dropdownActiveState, setDropdownActiveState] = useState('');
  const filterControl = useRef(null);

  const toggleDropdown = event => {
    if (filterControl.current.contains(event.target)) {
      setDropdownActiveState(
        dropdownActiveState === 'active' &&
          event.target.classList.contains('filter-control__button')
          ? ''
          : 'active'
      );
    } else {
      setDropdownActiveState('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', toggleDropdown);
    return () => {
      document.removeEventListener('mousedown', toggleDropdown);
    };
  }, [dropdownActiveState]);

  return (
    <div className="col-12 col-lg-4">
      <div
        className={`filter-control__container ${dropdownActiveState}`}
        ref={filterControl}
      >
        <div className="filter-control">
          <button className="filter-control__button">
            {getLabel(label, selection, type)}
          </button>
          <div className="filter-control__chevron"></div>
        </div>
        <div className="filter-control__dropdown">
          {_getSelectionMarkup(type, criteria, selection, eventHandler)}
        </div>
      </div>
    </div>
  );
};

FilterControlDropdown.propTypes = {};

export default FilterControlDropdown;
