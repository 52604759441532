import React from 'react';
import PropTypes from 'prop-types';

const SimpleText = ({ options }) => {
  if (options) {
    const { type, text } = options;
    switch (type) {
      case 'h1':
        return <h1>{text}</h1>;
      case 'h3':
        return <h3>{text}</h3>;
      case 'h4':
        return <h4>{text}</h4>;
      default:
        return <p>{text}</p>;
    }
  } else {
    return '';
  }
};

SimpleText.propTypes = {};

export default SimpleText;
